import React, { useEffect, useRef, useState } from "react"; 
import { ApiService } from "../../Components/Services/apiservices"; 
import { Swiper, SwiperSlide } from "swiper/react"; 
import Skeleton from 'react-loading-skeleton';
import ProductBox from "./product_box";
function TagWiseProducts() {
  const didMountRef = useRef(true);
  const [loading, setLoading] = useState()
  const [tagCategoriesData, setTagCategoriesData] = useState([]);
  useEffect(() => {
    if (didMountRef.current) {
      getTagCategoryData();
    }
    didMountRef.current = false;
  }, []);
  const getTagCategoryData = () => {
    setLoading(true)
    ApiService.fetchData("tags-wise-products").then((res) => {
      if (res.status == "success") {
        setTagCategoriesData(res.tagsData);
        setLoading(false)
      }
    });
  };
  const productCarouselOptions = {
    slidesPerView: "auto",
    spaceBetween: 15,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 2.2,
      },
      600: {
        slidesPerView: 2,
      },
      1000: {
        slidesPerView: 4.5,
      },
    },
  }; 
  return (
    <>
      {loading == true ? (
        <>
          {Array.from({ length: 2 }).map((_, index) => (
            <section className="sec-gap-top" key={index}>
              <div className="container">
                <div className="row">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className="col-lg-3 col-6">
                      <div className="product">
                        <figure className="product-media">
                          <Skeleton variant="rectangular" width={280} height={315} />
                        </figure>
                        <div className="product-details">
                          <h3 className="product-name">
                            <Skeleton variant="text" width={150} />
                          </h3>
                          <div className="product-price">
                            <Skeleton variant="text" width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ))}
        </>
      ) : tagCategoriesData.length > 0 ? (
        <>
          {tagCategoriesData.map((value, index) => {
            if (value.tagProducts.length > 0) {
              if (value.tag_grid_type === 1) {
                return (
                  <section className="sec-pad-small pt-0" key={index}>
                    <div className="container" key={index}>
                      <div className="section-title d-flex align-items-center justify-content-between mb-4">
                        <h2 className="mb-0">{value.tag_name}</h2>
                        <a href={"/collection/tag/" + value.tag_slug}>
                          View All <i className="d-icon-arrow-right"></i>
                        </a>
                      </div>
                      <div className="row">
                        {value.tagProducts.map((subvalue, indexProduct) => {

                          return (
                            <div className="col-lg-3 col-6" key={indexProduct}>
                              <ProductBox productValue={subvalue} indexProduct={indexProduct} classType="product" key={indexProduct} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                );
              } else {
                return (
                  <section className="sec-pad-small pt-0" key={index}>
                    <div className="container" key={index}>
                      <div className="section-title d-flex align-items-center justify-content-between mb-4">
                        <h2 className="mb-0">{value.tag_name}</h2>
                        <a href={"/collection/tag/" + value.tag_slug}>
                          View All <i className="d-icon-arrow-right"></i>
                        </a>
                      </div>
                      <Swiper {...productCarouselOptions}>
                        {value.tagProducts.map((subvalue, indexProduct) => {
                          return (
                            <SwiperSlide key={indexProduct}>
                              <ProductBox productValue={subvalue} indexProduct={indexProduct} classType="product" key={indexProduct} />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </section>
                );
              }
            }
          }
          )}
        </>
      ) :
        null} 
    </>
  );
}
export default TagWiseProducts;
