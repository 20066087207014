import React, { useEffect, useState, useRef } from "react";  
import { BrowserView, MobileView } from "react-device-detect"; 
function Error404() {
  const didMountRef = useRef(true);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  useEffect(() => {
    if (didMountRef.current) {
        setSpinnerLoading(false)
    }
    didMountRef.current = false;
  }, []);

  return (
    <> 
       <BrowserView>
         <section className="sec-pad text-center mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12" >
                <h1 className="mb-5">Oops, you found our 404 page</h1>
                <img src="/img/404error.png" alt="404 Error" style={{width : "300px"}} />
                <h5 className="mt-5">The page you requested does not exist.</h5>
                <a href="/" className="btn btn-primary-outline btn-medium mt-2">Continue Shopping</a>
              </div>
            </div>
          </div>
        </section>
       </BrowserView>

      <MobileView>
      <section className="sec-pad text-center mt-5">
        <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12" >
              <h1 className="mb-5">Oops, you found our 404 page</h1>
                <img src="/img/404error.png" alt="404 Error" style={{width : "200px"}} />
                <h5 className="mt-5">The page you requested does not exist.</h5>
                <a href="/" className="btn btn-primary-outline btn-medium mt-2">Continue Shopping</a>
              </div>
            </div>
          </div>
        </section>
      </MobileView>
    </>
  );
}
export default Error404;
