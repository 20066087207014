import React, { useEffect, useRef, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import SpinnerLoader from '../../Components/Elements/spinner_loader';
import constant from "../../Components/Services/constant";
import multiCurrency from "../../Components/Elements/multi_currrency";
import DataContext from "../Elements/eventContext";
import { addToCart } from "../Elements/add_to_cart";
import Loader from "react-js-loader";
function VariationModal() {
  const contextValues = useContext(DataContext);
  const didMountRef = useRef(true);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [arySelectedData, setArySelectedData] = useState([]);
  const [selvararray, setSelvararray] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [variationDataa, setVariationData] = useState([]);
  const [productData, setProductData] = useState({});
  let mrpValue = 0;
  let sellingPriceValue = 0;
  let discount = 0;
  useEffect(() => {
    if (didMountRef.current) {
      if (contextValues.productData && Object.keys(contextValues.productData).length > 0) {
        getProductDetails()
      }
    }
    didMountRef.current = false;
  }, []);

  const handleClose = () => {
    contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
  }

  const getProductDetails = () => {
    const dataString = {
      slug: contextValues.productData.product_slug,
    };

    ApiService.postData("product-details", dataString).then((res) => {
      if (res.status === "success") {
        setProductData(res.rowProductData)
        setSelvararray(res.selvararray);
        setVariationData(res.variationData);
        setTimeout(() => {
          mrpValue = parseFloat(res.rowProductData.product_price);
          sellingPriceValue = parseFloat(
            res.rowProductData.product_selling_price
          );
          if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
            discount = ((mrpValue - sellingPriceValue) / mrpValue) * 100;
            setDiscountPercentage(discount.toFixed(2));
          } 
          setSpinnerLoading(false);
        }, 1000);
      } else {
        setSpinnerLoading(false);
      }
    });
  };

  const variationSelect = (item, index) => {
    const updatedSelectedData = [...selvararray];
    updatedSelectedData[index] = item.terms_name;
    const variationNewArray = updatedSelectedData;
    const dataString = {
      variation: variationNewArray,
      product_id: productData.product_id,
    };

    ApiService.postData("variation-wise-price", dataString).then((res) => {
      setSelvararray(variationNewArray);
      productData.product_selling_price = res.data.pv_sellingprice;
      productData.product_price = res.data.pv_price;
      productData.product_stock = res.data.pv_quantity;
      productData.product_moq = res.data.pv_moq;
      productData.product_discount = res.data.pv_discount;
      if (item.variation_images) {
        productData.product_image =
          item.variation_images.pti_image != null
            ? item.variation_images.pti_image
            : constant.DEFAULT_IMAGE;
      } else {
        productData.product_image =
          productData.product_image != null
            ? productData.product_image
            : constant.DEFAULT_IMAGE;
      }
      mrpValue = parseFloat(res.data.pv_price);
      sellingPriceValue = parseFloat(res.data.pv_sellingprice);
      if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
        discount = ((mrpValue - sellingPriceValue) / mrpValue) * 100;
      }
      setDiscountPercentage(discount.toFixed(2));
      //setQuantity(1);
    });
  };

  const addtocart = async (addproduct) => {
    contextValues.setSpinnerCubLoader(addproduct.product_id)
    const productData = {
        product_id: Number(addproduct.product_id),
        product_name: addproduct.product_name,
        product_slug: addproduct.product_slug,
        product_image: addproduct.product_image
            ? addproduct.product_image
            : constant.DEFAULT_IMAGE,
        product_type: Number(addproduct.product_type),
        product_price: Number(addproduct.product_price),
        product_selling_price: Number(addproduct.product_selling_price),
        product_discount: addproduct.pv_discount,
        product_variation: selvararray,
        product_category_id: addproduct.product_category_id,
    };
    const updateStatus = await addToCart(productData, quantity, contextValues);
    if(updateStatus){
      contextValues.setSpinnerCubLoader(0)
      contextValues.setToggleCartModal(true)
      contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
    }else{
      contextValues.setSpinnerCubLoader(0)
    } 
  };
  const [quantity, setQuantity] = useState(1);
  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  return (
    <>
      <BrowserView>
        <Modal show={contextValues.toggleVariationModal} onHide={handleClose} className="right variationModal">
          {spinnerLoading ? (<SpinnerLoader />) :
            <div className="variationModalbox">
              <button onClick={handleClose} className="pop-close"><i className="d-icon-times"></i></button>
              <div className="vm-product">
                <figure className="product-media">
                  <img
                    src={
                      productData.product_image != null
                        ? productData.product_image
                        : constant.DEFAULT_IMAGE
                    }
                    alt={productData.product_name}
                  />
                </figure>
                <div className="product-detail">
                  <a href={"/product/" + productData.product_slug} className="product-name">{productData.product_name}</a>
                  <div className="product-price">
                    <ins className="new-price">
                      {multiCurrency(productData.product_selling_price)}
                    </ins>
                    {discountPercentage > 0 ? (
                      <>
                        <del className="old-price">
                          {multiCurrency(productData.product_price)}
                        </del>
                        <span className="off">{Math.round(discountPercentage)}% Off</span>
                      </>
                    ) : null}
                  </div>
                  {spinnerLoading === false && (
                    <>
                      {variationDataa.map((valueVariation, indexVariation) => {
                        if (valueVariation.attributes && valueVariation.attributes.attribute_type === 1) {
                          return (
                            <div className="dvariation" key={indexVariation}>
                              <label>
                                {valueVariation.attributes.attribute_name}:
                              </label>
                              <div className="dvariation-list">
                                {valueVariation.attr_terms.map(
                                  (
                                    valueVariationAttr,
                                    indexvalueVariationAttr
                                  ) => {
                                    const stringIncluded = selvararray.includes(
                                      valueVariationAttr.terms_name
                                    );
                                    const className = stringIncluded
                                      ? "color active"
                                      : "color";
                                    return (
                                      <a
                                        onClick={() =>
                                          variationSelect(
                                            valueVariationAttr,
                                            indexVariation
                                          )
                                        }
                                        className={className}
                                        key={indexvalueVariationAttr}
                                        data-src={constant.DEFAULT_IMAGE}
                                        href="javascript:void(0)"
                                        style={{
                                          backgroundColor:
                                            valueVariationAttr.terms_value,
                                          display: "block",
                                        }}
                                      ></a>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        } else if (
                          valueVariation.attributes && valueVariation.attributes.attribute_type === 2
                        ) {
                          return (
                            <div className="dvariation" key={indexVariation}>
                              <label>
                                {valueVariation.attributes.attribute_name}:
                              </label>
                              <div className="dvariation-list">
                                {valueVariation.attr_terms.map(
                                  (
                                    valueVariationAttr,
                                    indexvalueVariationAttr
                                  ) => {
                                    const stringIncluded = selvararray.includes(
                                      valueVariationAttr.terms_name
                                    );
                                    const className = stringIncluded
                                      ? "swatch active"
                                      : "swatch";
                                    return (
                                      <a
                                        onClick={() =>
                                          variationSelect(
                                            valueVariationAttr,
                                            indexVariation
                                          )
                                        }
                                        className={className}
                                        key={indexvalueVariationAttr}
                                        href="javascript:void(0)"
                                        style={{
                                          backgroundImage: `url(${valueVariationAttr.variation_images !=
                                            null
                                            ? valueVariationAttr
                                              .variation_images.pti_image
                                            : constant.DEFAULT_IMAGE
                                            })`,
                                          backgroundColor: "#c8c7ce",
                                        }}
                                      >
                                        <img
                                          src={
                                            valueVariationAttr.variation_images !=
                                              null
                                              ? valueVariationAttr
                                                .variation_images.pti_image
                                              : constant.DEFAULT_IMAGE
                                          }
                                          alt={productData.product_name}
                                          width="100"
                                          height="100"
                                        />
                                      </a>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        } else if (
                          valueVariation.attributes && valueVariation.attributes.attribute_type === 3
                        ) {
                          return (
                            <div className="dvariation" key={indexVariation}>
                              <label>
                                {valueVariation.attributes.attribute_name}:
                              </label>

                              <div className="dvariation-list">
                                {valueVariation.attr_terms.map(
                                  (
                                    valueVariationAttr,
                                    indexvalueVariationAttr
                                  ) => {
                                    const stringIncluded =
                                      selvararray.includes(
                                        valueVariationAttr.terms_name
                                      );
                                    const className = stringIncluded
                                      ? "size active"
                                      : "size";
                                    return (
                                      <a
                                        onClick={() =>
                                          variationSelect(
                                            valueVariationAttr,
                                            indexVariation
                                          )
                                        }
                                        className={className}
                                        href="javascript:void(0)"
                                        key={indexvalueVariationAttr}
                                      >
                                        {valueVariationAttr.terms_name}
                                      </a>
                                    );
                                  }
                                )}
                              </div>

                            </div>
                          );
                        }
                        return null;
                      })}
                    </>
                  )}
                </div>
              </div>
              <hr className="product-divider mb-3" />
              <div className="product-button">
                <div className="pbqty">
                  <button className="quantity-minus d-icon-minus" onClick={handleDecrease}></button>
                  <input type="number" className="quantity" value={quantity} />
                  <button className="quantity-plus d-icon-plus" onClick={handleIncrease}></button>
                </div>
                <button
                  className="btn btn-primary mb-2"
                  onClick={(e) => addtocart(productData)}
                >
                  {contextValues.spinnerCubLoader == Number(productData.product_id) ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : <><i className="d-icon-bag me-2"></i>Add To Cart </>}
                </button>
              </div>
            </div>
          }
        </Modal>
      </BrowserView>
      <MobileView>
        <Modal show={contextValues.toggleVariationModal} onHide={handleClose} className="bottom mvariationModal">
          <div className="mvariationModalbox">
            {spinnerLoading ? (<SpinnerLoader />) :
              <>
                <button onClick={handleClose} className="pop-close"><i className="d-icon-times"></i></button>
                <div className="mvm-product">
                  <figure className="product-media">
                    <img
                      src={
                        productData.product_image != null
                          ? productData.product_image
                          : constant.DEFAULT_IMAGE
                      }
                      alt={productData.product_name}
                    />
                  </figure>
                  <div className="product-detail">
                    <a href={"/product/" + productData.product_slug} className="product-name">{productData.product_name}</a>
                    <div className="product-price">
                      <ins className="new-price">
                        {multiCurrency(productData.product_selling_price)}
                      </ins>
                      {discountPercentage > 0 ? (
                        <>
                          <del className="old-price">
                            {multiCurrency(productData.product_price)}
                          </del>
                          <span className="off">{Math.round(discountPercentage)}% Off</span>
                        </>
                      ) : null}
                    </div>
                    {spinnerLoading === false && (
                      <>
                        {variationDataa.map((valueVariation, indexVariation) => {
                          if (valueVariation.attributes && valueVariation.attributes.attribute_type === 1) {
                            return (
                              <div className="dvariation" key={indexVariation}>
                                <label>
                                  {valueVariation.attributes.attribute_name}:
                                </label>
                                <div className="dvariation-list">
                                  {valueVariation.attr_terms.map(
                                    (
                                      valueVariationAttr,
                                      indexvalueVariationAttr
                                    ) => {
                                      const stringIncluded = selvararray.includes(
                                        valueVariationAttr.terms_name
                                      );
                                      const className = stringIncluded
                                        ? "color active"
                                        : "color";
                                      return (
                                        <a
                                          onClick={() =>
                                            variationSelect(
                                              valueVariationAttr,
                                              indexVariation
                                            )
                                          }
                                          className={className}
                                          key={indexvalueVariationAttr}
                                          data-src={constant.DEFAULT_IMAGE}
                                          href="javascript:void(0)"
                                          style={{
                                            backgroundColor:
                                              valueVariationAttr.terms_value,
                                            display: "block",
                                          }}
                                        ></a>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          } else if (
                            valueVariation.attributes && valueVariation.attributes.attribute_type === 2
                          ) {
                            return (
                              <div className="dvariation" key={indexVariation}>
                                <label>
                                  {valueVariation.attributes.attribute_name}:
                                </label>
                                <div className="dvariation-list">
                                  {valueVariation.attr_terms.map(
                                    (
                                      valueVariationAttr,
                                      indexvalueVariationAttr
                                    ) => {
                                      const stringIncluded = selvararray.includes(
                                        valueVariationAttr.terms_name
                                      );
                                      const className = stringIncluded
                                        ? "swatch active"
                                        : "swatch";
                                      return (
                                        <a
                                          onClick={() =>
                                            variationSelect(
                                              valueVariationAttr,
                                              indexVariation
                                            )
                                          }
                                          className={className}
                                          key={indexvalueVariationAttr}
                                          href="javascript:void(0)"
                                          style={{
                                            backgroundImage: `url(${valueVariationAttr.variation_images !=
                                              null
                                              ? valueVariationAttr
                                                .variation_images.pti_image
                                              : constant.DEFAULT_IMAGE
                                              })`,
                                            backgroundColor: "#c8c7ce",
                                          }}
                                        >
                                          <img
                                            src={
                                              valueVariationAttr.variation_images !=
                                                null
                                                ? valueVariationAttr
                                                  .variation_images.pti_image
                                                : constant.DEFAULT_IMAGE
                                            }
                                            alt={productData.product_name}
                                            width="100"
                                            height="100"
                                          />
                                        </a>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          } else if (
                            valueVariation.attributes && valueVariation.attributes.attribute_type === 3
                          ) {
                            return (
                              <div className="dvariation" key={indexVariation}>
                                <label>
                                  {valueVariation.attributes.attribute_name}:
                                </label>

                                <div className="dvariation-list">
                                  {valueVariation.attr_terms.map(
                                    (
                                      valueVariationAttr,
                                      indexvalueVariationAttr
                                    ) => {
                                      const stringIncluded =
                                        selvararray.includes(
                                          valueVariationAttr.terms_name
                                        );
                                      const className = stringIncluded
                                        ? "size active"
                                        : "size";
                                      return (
                                        <a
                                          onClick={() =>
                                            variationSelect(
                                              valueVariationAttr,
                                              indexVariation
                                            )
                                          }
                                          className={className}
                                          href="javascript:void(0)"
                                          key={indexvalueVariationAttr}
                                        >
                                          {valueVariationAttr.terms_name}
                                        </a>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </>
                    )}
                  </div>
                </div>
                <div className="spacer1 mb-3 mt-3"></div>
                <div className="product-button">
                  <div className="pbqty">
                    <button className="quantity-minus d-icon-minus" onClick={handleDecrease}></button>
                    <input type="number" className="quantity" value={quantity} />
                    <button className="quantity-plus d-icon-plus" onClick={handleIncrease}></button>
                  </div>
                  <button
                    className="btn btn-primary mb-2"
                    onClick={(e) => addtocart(productData)}
                  >
                    <i className="d-icon-bag me-2"></i>Add To Cart
                  </button>
                </div>
              </>
            } 
          </div>
        </Modal>
      </MobileView>

    </>
  );
}
export default VariationModal;
