import React, { useEffect, useState, useContext } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../Services/apiservices";
import { toast } from "react-toastify";
import multiCurrency from "./multi_currrency";
import StarRating from "./starrating";
import DataContext from './eventContext';
import { addToCart } from "./add_to_cart";
import Loader from "react-js-loader";
function ProductBox({ productValue, indexProduct, classType }) {
  const contextValues = useContext(DataContext);
  const addtofav = (productId) => {
    const dataString = {
      product_id: productId,
    };
    ApiService.postData("add-to-fav", dataString).then((res) => {
      if (res.data.status == "success") {
        var element = document.getElementById("wishlisticon" + productId);
        element.classList.remove("d-icon-heart", "d-icon-heart-full");
        element.classList.add(res.data.notification);
        if (res.data.notification === "d-icon-heart") {
          toast.success("Removed from wishlist");
        } else {
          toast.success("Added to Wishlist");
        }
      }
    });
  };
  let mrpValue = parseFloat(productValue.product_price);
  let sellingPriceValue = parseFloat(
    productValue.product_selling_price
  );
  let discount = 0;
  if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
    discount = (((mrpValue - sellingPriceValue) / mrpValue) * 100);
  } else {
    discount = 0;
  }
  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  const quickviewModal = (data) => {
    contextValues.setProductData(data)
    setTimeout(() => {
      contextValues.setToggleQuickViewModal(!contextValues.toggleQuickViewModal)
    }, 100)
  }
  const variationModal = (data) => {
    contextValues.setProductData(data)
    setTimeout(() => {
      contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
    }, 100)
  }
  const addtocartList = async (addproduct) => {
    contextValues.setSpinnerCubLoader(addproduct.product_id)
    const productData = {
      product_id: Number(addproduct.product_id),
      product_name: addproduct.product_name,
      product_slug: addproduct.product_slug,
      product_image: addproduct.product_image
        ? addproduct.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(addproduct.product_type),
      product_price: Number(addproduct.product_price),
      product_selling_price: Number(addproduct.product_selling_price),
      product_discount: addproduct.pv_discount,
      product_variation: [],
      product_category_id: addproduct.product_category_id,
    };
    const updateStatus = await addToCart(productData, 1, contextValues);
    if (updateStatus) {
      contextValues.setToggleQuickViewModal(false)
      contextValues.setToggleCartModal(true)
      contextValues.setSpinnerCubLoader(0)
    } else {
      contextValues.setSpinnerCubLoader(0)
    }
  }
  return (
    <>
      <div className={classType} key={indexProduct}>
        <figure className="product-media">
          {productValue.product_type === 0 ? (
            productValue.product_inventory === 1 ? (
              productValue.product_stock === 0 ? (
                productValue.product_backorder === 0 || productValue.product_backorder === 1 ? (
                  // <div className="stock-text-p mb-0"> Out of Stock</div>
                  <div className="outofstox">
                  <svg width="48" height="46" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.9499 0C28.3999 0 27.9361 1.44696 27.9361 2.60412V27.9718L24.5708 25.9718L21.2055 27.9718L17.8402 25.9718L14.4749 27.9718L11.1096 25.9718L7.74436 27.9718L4.37907 25.9718L1.01378 27.9718V2.6037C1.01378 1.44655 0.549931 0 0 0H28.9499Z" fill="#ed1c24"></path></svg>
                  <span>Out of Stock</span>
                </div>
                  // <div className="outofstox mb-0"> Out of Stock</div>

                ) : (
                  null
                )
              ) : (
                null
              )
            ) : (
              null
            )
          ) : (
            productValue.product_stock === 0 ? (
              // <div className="stock-text-p mb-0"> Out of Stock</div>
                      <div className="outofstox">
                      <svg width="48" height="46" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.9499 0C28.3999 0 27.9361 1.44696 27.9361 2.60412V27.9718L24.5708 25.9718L21.2055 27.9718L17.8402 25.9718L14.4749 27.9718L11.1096 25.9718L7.74436 27.9718L4.37907 25.9718L1.01378 27.9718V2.6037C1.01378 1.44655 0.549931 0 0 0H28.9499Z" fill="#ed1c24"></path></svg>
                      <span>Out of Stock</span>
                    </div>

            ) : (
              null
            )
          )}
          <a
            href={
              "/product/" +
              productValue.product_slug
            }
          >
            <img
              src={
                productValue.product_image != null
                  ? productValue.product_image
                  : constant.DEFAULT_IMAGE
              }
              alt={productValue.product_name}
              width="280"
              height="315"
            />
            {productValue.gallery && productValue.gallery.length > 0 ? (
              <img
                src={
                  productValue.gallery[0].gallery_image
                    ? productValue.gallery[0].gallery_image
                    : constant.DEFAULT_IMAGE
                }
                alt={productValue.product_name}
                width="280"
                height="315"
              />
            ) : null}
          </a>
          {productValue.product_tag_name != "" ? (
            // <div className="product-label-group">
            <div>
              {productValue.product_tag_name
                .split(", ")
                .map((tagvalue, indextag) => {
                  return (
                    // <label
                    //   className="product-label label-new"
                    //   key={indextag}
                    // >
                    //   {tagvalue}
                    // </label>

                    <div className="offtags" key={indextag}>
                      <svg width="48" height="46" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.9499 0C28.3999 0 27.9361 1.44696 27.9361 2.60412V27.9718L24.5708 25.9718L21.2055 27.9718L17.8402 25.9718L14.4749 27.9718L11.1096 25.9718L7.74436 27.9718L4.37907 25.9718L1.01378 27.9718V2.6037C1.01378 1.44655 0.549931 0 0 0H28.9499Z" fill="#ed1c24"></path></svg>
                      <span>{tagvalue}</span>
                    </div>

                  );
                })}
            </div>
          ) : null}
          <div className="product-action-vertical">
            {contextValues.userToken ? (
              productValue.ufp_id > 0 ? (
                <a
                  href="javascript:void(0)"
                  className="btn-product-icon btn-wishlist"
                  title="Add to wishlists"
                  onClick={(e) =>
                    addtofav(productValue.product_id)
                  }
                >
                  <i
                    className="d-icon-heart-full"
                    id={
                      "wishlisticon" +
                      productValue.product_id
                    }
                  ></i>
                </a>
              ) : (
                <a
                  href="javascript:void(0)"
                  className="btn-product-icon btn-wishlist"
                  title="Add to wishlist"
                  onClick={(e) =>
                    addtofav(productValue.product_id)
                  }
                >
                  <i
                    className="d-icon-heart"
                    id={
                      "wishlisticon" +
                      productValue.product_id
                    }
                  ></i>
                </a>
              )
            ) : (
              <a
                href="javascript:void(0)"
                className="btn-product-icon btn-wishlist"
                title="Add to wishlist"
                onClick={loginModal}
              >
                <i className="d-icon-heart"></i>
              </a>
            )}
          </div>
          <div className="product-action">
            <a
              href="javascript:void(0)"
              className="btn-product btn-quickview"
              title="Quick View"
              onClick={() => {
                quickviewModal(productValue);
              }}
            >
              Quick View
            </a>
          </div>
        </figure>
        <div className="product-details">
          <div className="product-details-inner">
            <h3 className="product-name">
              <a
                href={
                  "/product/" +
                  productValue.product_slug
                }
              >
                {productValue.product_name}
              </a>
            </h3>
            <div className="product-price">
              <ins className="new-price">
                {multiCurrency(
                  productValue.product_selling_price
                )}
              </ins>
              {discount > 0 ? (
                <>
                  <del className="old-price">
                    {multiCurrency(
                      productValue.product_price
                    )}
                  </del>
                  <span className="off">
                    {(Math.round(discount))}% Off
                  </span>
                </>
              ) : null}
            </div>
            {productValue.product_rating &&
              productValue.product_rating > 0 ? (
              <div className="ratings-container">
                <StarRating
                  numberOfStars={
                    productValue.product_rating
                  }
                />
                <span>
                  ( {productValue.product_review} reviews )
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {productValue.product_type === 0 ? (
              contextValues.spinnerCubLoader === productValue.product_id ? (
                <button
                  className="btn btn-primary-outline btn-small"
                >
                  <Loader type="spinner-cub" bgColor="#fff" color="#fff" size={20} />
                </button>

              ) : (
                <button
                  className="btn btn-primary-outline btn-small"
                  onClick={(e) => addtocartList(productValue)}
                >
                  Add To Cart
                </button>
              )
            ) : (
              <button
                className="btn btn-primary-outline btn-small"
                onClick={() => {
                  variationModal(productValue);
                }}
              >
                Add To Cart
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductBox