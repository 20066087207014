import React, { useEffect, useRef, useState } from "react";
import constant from "../Services/constant";
import { ApiService } from "../Services/apiservices";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import StarRating from "./starrating";
import multiCurrency from "../../Components/Elements/multi_currrency";
import numeral from 'numeral';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function FeaturedVideoProducts() {
  const didMountRef = useRef(true);
  const [resProductsData, setResProductsData] = useState([]);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (didMountRef.current) {
      featuredvideoproductlist();
    }
    didMountRef.current = false;
  }, []);
  const featuredvideoproductlist = () => {
    setLoading(true)
    ApiService.fetchData("featured-video-product-list").then((res) => {
      if (res.status == "success") {
        setResProductsData(res.resProductsData);
        setLoading(false)
      }
    });
  };
  const productvCarouselOptions = {
    loop: false,
    spaceBetween: 15,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1.5,
      },
      600: {
        slidesPerView: 1.5,
      },
      1000: {
        slidesPerView: 4.5,
      },
    },
  };
  return (
    <>
      {loading ? (
        <section className="sec-pad-small pt-0">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              {[...Array(4)].map((_, index) => (
                <div className="col-lg-3" key={index}>
                  <figure className="product-media">
                    <Skeleton variant="text" width={300} height={400} />
                  </figure>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : resProductsData && resProductsData.length > 0 ? (
        <section className="sec-pad-small pt-0">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12">
                <Swiper {...productvCarouselOptions}>
                  {resProductsData.map((value, index) => {
                    const mrpValue = parseFloat(value.product_price);
                    const sellingPriceValue = parseFloat(value.product_selling_price);
                    const discount = !isNaN(mrpValue) && !isNaN(sellingPriceValue)
                      ? ((mrpValue - sellingPriceValue) / mrpValue * 100).toFixed(2)
                      : 0;
  
                    return (
                      <SwiperSlide key={index}>
                        <div className="product product-wvideo">
                          <figure className="product-media">
                            <a href={`/product/${value.product_slug}`}>
                              {value.product_video ? (
                                <video
                                  src={value.product_video}
                                  autoPlay
                                  loop
                                  muted
                                  playsInline
                                />
                              ) : (
                                <img
                                  src={value.product_image || constant.DEFAULT_IMAGE}
                                  alt={value.product_name}
                                  width="280"
                                  height="315"
                                />
                              )}
                            </a>
                          </figure>
                          <div className="product-details">
                            <h3 className="product-name">
                              <a href={`/product/${value.product_slug}`}>
                                {value.product_name}
                              </a>
                            </h3>
                            <div className="product-price">
                              <ins className="new-price">
                                {multiCurrency(value.product_selling_price)}
                              </ins>
                              {discount > 0 && (
                                <>
                                  <del className="old-price">
                                    {multiCurrency(value.product_price)}
                                  </del>
                                  <span className="off">{discount}% Off</span>
                                </>
                              )}
                            </div>
                            {value.product_rating && value.product_rating > 0 && (
                              <div className="ratings-container">
                                <StarRating numberOfStars={value.product_rating} />
                                <span>({value.product_review} reviews)</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
  
}
export default FeaturedVideoProducts;
