import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";

function HomeTestimonials() {
  const didMountRef = useRef(true);
  const [resTestimonialData, setResTestimonialData] = useState([]);
  const [testimonialImagePath, setTestimonialImagePath] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [loopsq, setLoopsq] = useState(false);

  const swiperRef = useRef(null);
  useEffect(() => {
    if (didMountRef.current) {
      getTestimonialData();
      handleSlideChange()
    }
    didMountRef.current = false;
  }, []);

  const getTestimonialData = () => {
    ApiService.fetchData("featured-testimonial").then((res) => {
      if (res.status === "success") {
        setResTestimonialData(res.resTestimonialData);
        setTestimonialImagePath(res.testimonial_image_path);
      }
    });
  };

  const handleSlideChange = (swiper) => {
    if (swiper && typeof swiper.realIndex === "number") {
      console.log('Swiper Index:', swiper.realIndex);
      setActiveIndex(swiper.realIndex);
      setLoopsq(true)
    } else {
      console.warn('Swiper not initialized yet');
    }
  };


  return (
    <section className="sec-pad bg-light testimonilaSection">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4">
            {resTestimonialData.length > 0 && (
              <div className="testimonialContent">
                {resTestimonialData[activeIndex]?.testimonial_rating && (
                  <div className="testimonial-stars">
                    {[...Array(5)].map((_, starIndex) => (
                      <i
                        key={starIndex}
                        className={
                          starIndex <
                          resTestimonialData[activeIndex]?.testimonial_rating
                            ? "d-icon-star-full fill"
                            : "d-icon-star-full"
                        }
                      ></i>
                    ))}
                  </div>
                )}
                <div className="testimonialName">
                  {resTestimonialData[activeIndex]?.testimonial_name}
                </div>
                <p>{resTestimonialData[activeIndex]?.testimonial_designation}</p>
                
                <div
                  className="testiminialtext"
                  dangerouslySetInnerHTML={{
                    __html: resTestimonialData[activeIndex]?.testimonial_desc,
                  }}
                ></div>
              </div>
            )}
          </div>
          <div className="col-lg-8">
            <Swiper
              ref={swiperRef}
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={25}
              slidesPerView={3}
              centeredSlides={true}
              loop={loopsq}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
                // when window width is >= 1280px
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
              }}
              
              onSlideChange={handleSlideChange}
            >
              {resTestimonialData.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="testimonialMedia">
                    <img
                      src={
                        testimonial.testimonial_image != null
                          ? testimonialImagePath +
                            "/" +
                            testimonial.testimonial_image
                          : constant.DEFAULT_IMAGE
                      }
                      alt="Testimonial"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeTestimonials;
