import React, { useEffect, useState, useRef, useContext } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import sessionCartData from "../../Components/Elements/cart_session_data";
import constant from "../Services/constant";
import multiCurrency from "../../Components/Elements/multi_currrency";
import DataContext from "../Elements/eventContext";
import Modal from "react-bootstrap/Modal";
import Loader from "react-js-loader";
import { addToCart, minusToCart } from "../Elements/add_to_cart";
import { toast } from "react-toastify";
function CartModal() {
  const didMountRef = useRef(true);
  const [recommendedData, setRecommendedProductsData] = useState([]);
  const [selvararray, setSelvararray] = useState([]);
  const sessionData = sessionCartData();
  const cartSessionData = sessionData[1];
  const contextValues = useContext(DataContext);
  let { itemTotal } = 0;
  useEffect(() => {
    if (didMountRef.current) {
      recommendedproductslist();
    }
    didMountRef.current = false;
  }, []);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const recommendedproductslist = () => {
    ApiService.fetchData("recommended-products-list").then((res) => {
      if (res.status === "success") {
        setRecommendedProductsData(res.recommendedproducts);
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });
  };

  const plusToCart = async (productValue, index) => {
    contextValues.setSpinnerCubLoader(index)
    const productData = {
      product_id: Number(productValue.product_id),
      product_name: productValue.product_name,
      product_slug: productValue.product_slug,
      product_image: productValue.product_image
        ? productValue.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(productValue.product_type),
      product_price: Number(productValue.product_price),
      product_selling_price: Number(productValue.product_selling_price),
      product_discount: productValue.product_discount,
      product_variation: productValue.product_variation,
      product_category_id: productValue.product_category_id,
    };
    const updateStatus = await addToCart(productData, 1, contextValues);
    if (updateStatus) {
      contextValues.setSpinnerCubLoader(0)
    } else {
      contextValues.setSpinnerCubLoader(0)
    }
  };

  const removeToCart = (productValue, index) => {
    contextValues.setSpinnerCubLoader(index)
    if (minusToCart(productValue, productValue.product_variation, contextValues)) {
      setTimeout(() => {
        contextValues.setSpinnerCubLoader(0)
      }, 500);
    } else {
      setTimeout(() => {
        contextValues.setSpinnerCubLoader(0)
      }, 500);
    }
  };

  const checkoutModal = () => {
    const productData = {
      sessionItemsSummary: cartSessionData
    };
    ApiService.postData("checkOrderSummary", productData).then((res) => {
      if (res.status === 'success') {
        localStorage.removeItem("CART_SESSION");
        localStorage.setItem("CART_SESSION", JSON.stringify(res.updatedSessionData));
        const cartSession = localStorage.getItem("CART_SESSION");
        const parsedCartSession = cartSession ? JSON.parse(cartSession) : [];
        contextValues.setCartSessionData(parsedCartSession)
        const hasItemWithStatusTrue = parsedCartSession.some((item) => {
          return (item.product_item_status === true);
        });
        if (!hasItemWithStatusTrue) {
          contextValues.setToggleCartModal(!contextValues.toggleCartModal)
          contextValues.setToggleCheckoutModal(!contextValues.toggleCheckoutModal)
        } else {
        }
      } else {
        toast.error(res.message)
        return;
      }
    }).catch((error) => {
    });

  }

  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }

  const variationModal = (data) => {
    contextValues.setProductData(data)
    setTimeout(() => {
      contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
    }, 100)
  }

  const addtocartList = async (addproduct) => {
    contextValues.setSpinnerCubLoader(addproduct.product_id)
    const updateStatus = await addToCart(addproduct, selvararray, contextValues);
    if (updateStatus) {
      contextValues.setSpinnerCubLoader(0)
    } else {
      contextValues.setSpinnerCubLoader(0)
    }
  }

  return (
    <>
      <Modal show={contextValues.toggleCartModal} onHide={cartModal} className="right cartmodal">
        {contextValues.cartSessionData.length > 0 ?
          <>
            <div className="cartSection">
              <div className="cartHeader">
                <h6 className="mb-0">Shopping Cart</h6>
                <button type="button" className="cart-close" onClick={cartModal}><i className="ri-close-line"></i></button>
              </div>
              <div className="cartScoller">
                {contextValues.cartSessionData.map((value, index) => {
                  itemTotal = contextValues.cartSessionData.reduce(
                    (total, value) =>
                      total + value.product_selling_price * value.quantity,
                    0
                  );
                  return (
                    <div className="cartProduct" key={value.product_id}>
                      <figure className="cartProduct-media">
                        <a href={"/product/" + value.product_slug}>
                          <img src={value.product_image}></img>
                        </a>
                      </figure>
                      <div className="cartProduct-details">
                        <a href={"/product/" + value.product_slug} className="cartProductName">{value.product_name}</a>
                        <div className="cartProduct-footer">
                          <div className="cartProductPrice">
                            <ins className="new-price">{multiCurrency(value.product_selling_price)}</ins>
                            <del className="old-price">{multiCurrency(value.product_price)}</del>
                          </div>
                          <div className="cartProductqty">
                            {contextValues.spinnerCubLoader == index + Number(1) ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : <>
                              <span onClick={(e) => removeToCart(value, index + Number(1))}><i className="ri-subtract-line"></i></span>
                              <span>{value.quantity}</span>
                              <span onClick={(e) => plusToCart(value, index + Number(1))}><i className="ri-add-line"></i></span>
                            </>
                            }
                          </div>
                        </div>
                        {value.product_item_status ? <div style={{ 'color': 'red' }}>{value.product_item_status_message}</div> : null}
                      </div>
                    </div>
                  );
                })}
                {recommendedData.length > 0 && (
                  <div className="cartSectionRecom">
                    <div className="cartSectionRecomheader">
                      <h6 className="tx-14 mb-0">You May Also Like</h6>
                    </div>
                    <div className="cartSectionRecom-inner">
                      {recommendedData.map((value, index) => (

                        <div className="product" key={value.product_id}>
                          <figure className="product-media">
                            <a href={"/product/" + value.product_slug}>
                              <img
                                src={value.product_image ? value.product_image : constant.DEFAULT_IMAGE}
                                alt={value.product_name}
                                width="280"
                                height="315"
                              />
                            </a>
                          </figure>
                          <div className="product-details">
                            <h3 className="product-name">
                              <a href={"/product/" + value.product_slug}>{value.product_name}</a>
                            </h3>
                            <div className="product-price">
                              <ins className="new-price">{multiCurrency(value.product_selling_price)}</ins>
                            </div>
                            {value.product_type === 0 ? (
                              contextValues.spinnerCubLoader === value.product_id ? (
                                <Loader type="spinner-cub" bgColor="#fff" color="#fff" size={20} />
                              ) : (
                                <button
                                  className="btn btn-primary btn-cartrec"
                                  onClick={(e) => addtocartList(value)}
                                >
                                  Add
                                </button>
                              )
                            ) : (
                              <button
                                className="btn btn-primary btn-cartrec"
                                onClick={() => {
                                  variationModal(value);
                                }}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>

                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="cartFooter">
                <div className="d-flex mb-4 justify-content-between">
                  <div><h6 className="mb-1">Estimated Total </h6><p className="mb-0 tx-12">Discount code to be applied at checkout.</p></div>
                  <div className="d-flex justify-content-end"><div className="carttotle">{multiCurrency(itemTotal)}</div></div>
                </div>
                <a href="javascript:void(0)" onClick={(e) => checkoutModal()}><button type="button" className="btn btn-primary btn-block btn-large">Checkout</button></a>
                {/* <a href="/checkout"><button type="button" className="btn btn-primary btn-block btn-large">Checkout</button></a> */}
              </div>
            </div>
          </>
          :
          <>
            <div className="cartSection">
              <div className="noimg"><img src="/img/emplty-cart-removebg-preview.png" className="wd-200 mb-20" />
                <h6>No Products in Cart</h6>
                <p className="tx-gray">Look like you have not added anythings to you cart. Go agead &amp; explore top categories</p>
                <a href="/" className="btn btn-primary-outline btn-medium">Start Shopping</a>
              </div>
            </div>
          </>
        }
      </Modal>
    </>
  );
}
export default CartModal;
