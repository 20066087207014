import { toast } from "react-toastify";
import { ApiService } from "../Services/apiservices";
const addToCart = (productData, quantity, contextValues) => {
    return new Promise((resolve, reject) => {
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        const existingProductIndex = cartSession.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.product_variation) ===
                JSON.stringify(productData.product_variation)
            );
        });
        if (existingProductIndex !== -1) {
            cartSession[existingProductIndex].quantity += quantity;
            quantity = cartSession[existingProductIndex].quantity
        } else {
            cartSession.push({ ...productData, quantity: quantity });
            quantity = quantity
        }
        const dataString = {
            product_id: Number(productData.product_id),
            product_variation: productData.product_variation,
            quantity: quantity,
        };
        ApiService.postData("addToCart", dataString).then((res) => {
            if (res.status === 'success') {  
                localStorage.removeItem("COUPON_SESSION");
                contextValues.setCouponSession({})
                localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
                let cartSessionCount = localStorage.getItem("CART_SESSION");
                cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
                contextValues.setCartSessionData(cartSessionCount)
                contextValues.setCartCount(cartSessionCount.length)
                toast.success(res.message);
                resolve(true);
            } else {
                toast.error(res.message);
                resolve(false);
            }
        }).catch((error) => {
            toast.error(error); 
            resolve(false);
        });
    });
};

const minusToCart = (productData, selvararray, contextValues) => {
    let cartSession = localStorage.getItem("CART_SESSION");
    cartSession = cartSession ? JSON.parse(cartSession) : [];
    const existingProductIndex = cartSession.findIndex((item) => {
        return (
            item.product_id === productData.product_id &&
            JSON.stringify(item.product_variation) ===
            JSON.stringify(selvararray)
        );
    });

    if (existingProductIndex !== -1) {
        if (cartSession[existingProductIndex].quantity === 1) {
            cartSession.splice(existingProductIndex, 1);
        } else {
            cartSession[existingProductIndex].quantity -= 1;
        }
        localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
    }
    let cartSessionCount = localStorage.getItem("CART_SESSION");
    cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
    contextValues.setCartSessionData(cartSessionCount)
    contextValues.setCartCount(cartSessionCount.length)
    localStorage.removeItem("COUPON_SESSION");
    contextValues.setCouponSession({})
    return true;
};

const removeToCart = (productData, selvararray, contextValues) => {
    return new Promise((resolve, reject) => {
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        const existingProductIndex = cartSession.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.product_variation) ===
                JSON.stringify(selvararray)
            );
        });

        if (existingProductIndex !== -1) {
            cartSession.splice(existingProductIndex, 1);
            localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
        }
        let cartSessionCount = localStorage.getItem("CART_SESSION");
        cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
        contextValues.setCartSessionData(cartSessionCount)
        contextValues.setCartCount(cartSessionCount.length)
        localStorage.removeItem("COUPON_SESSION");
        contextValues.setCouponSession({})
        resolve(true) ;
    });
};

export { addToCart, minusToCart, removeToCart};