import React, { useEffect, useState, useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant"; 
import Testimonials from "../../Components/Elements/testimonials";
import Certification from "../../Components/Elements/certification";
import CategoryWiseProducts from "../../Components/Elements/category_wise_products";
import TagWiseProducts from "../../Components/Elements/tag_wise_products";
import HomeTopBanner from "../../Components/Elements/home_top_banner";
import FeaturedCategories from "../../Components/Elements/featured_categories";
import GridBannerFirst from "../../Components/Elements/grid_banner_first";
import GridBannerSecond from "../../Components/Elements/grid_banner_second";
import FeaturedVideoProducts from "../../Components/Elements/featured_video_products";
import { Helmet } from "react-helmet";
import SecondCategoryWiseProducts from "../../Components/Elements/second_category_wise_product";
import GridBannerThird from "../../Components/Elements/grid_banner_third";
import HomeTestimonials from "./home_testimonials";
function Home() { 
  const didMountRef = useRef(true); 
  const [pageData , setPageData] = useState("")
  useEffect(() => {
    if(didMountRef.current){
      const getPageData = {
        slug: "home",
      };
      ApiService.postData("page-content", getPageData).then((res) => {
        if (res.status == "success") {
          setPageData(res.data)
        }
      });
    }
    didMountRef.current = false;
  }, []); 
  return (
    <>
      <Helmet>
      <title>{pageData.page_meta_title}</title>
      <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :"Chokhi Dhani Foods"} />
      {pageData.page_meta_keyword != null ?<meta name="keywords" content={pageData.page_meta_keyword} />:""}
      <link rel="canonical" href={window.location.href} />
      <meta property="og:title" content={pageData.page_meta_title} />
      <meta property="og:image" content= {constant.FRONT_URL+'img/logo.png'}/>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:description" content= {pageData.page_meta_desc != null ?pageData.page_meta_desc:"Chokhi Dhani Foods"} />
      <meta name="twitter:title" content={pageData.page_meta_title} />
      <meta name="twitter:description" content={pageData.page_meta_desc != null ?pageData.page_meta_desc:"Chokhi Dhani Foods"} />
      <meta property="twitter:image" content={constant.FRONT_URL + 'img/logo.png'} />
      <meta name="twitter:url" content={window.location.href} />
      </Helmet> 
      <Header/>
      <HomeTopBanner /> 
      <FeaturedCategories />
      <GridBannerFirst /> 
      <TagWiseProducts/>
      <FeaturedVideoProducts />
      <CategoryWiseProducts/>
      <GridBannerSecond />
      <SecondCategoryWiseProducts/>
      {/* <GridBannerThird /> */}
      <HomeTestimonials/>
      {/* <Testimonials /> */}
      <Certification />
      <Footer /> 
    </>
  );
}
export default Home;
