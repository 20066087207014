import React, { useEffect, useState, useRef,useContext } from "react";
import Modal from "react-bootstrap/Modal";
import CartModal from '../Modals/cart_modal';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import DataContext from './eventContext';
import QuickViewModal from "../Modals/quick_view_modal";
import VariationModal from "../Modals/variation_modal";
import sessionCartData from "./cart_session_data";
import { ApiService } from "../Services/apiservices"; 
import CheckoutModal from "../Modals/checkout_modal";
function MobileHeader({ PageName, Route, cartCount=0}) {
  const Navigate = useNavigate();
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();
  const contextValues = useContext(DataContext); 
  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }

  const [showHeaderStatus,SetShowHeaderStatus] = useState(false)
  const location = useLocation();
  const { slug } = useParams();
  useEffect(() => {
    if (didMountRef.current) {
      contextValues.setAddressSession(dataArray[0])
      contextValues.setCartSessionData(dataArray[1])
      contextValues.setCouponSession(dataArray[2])
      contextValues.setCartSummary(dataArray[3])
      contextValues.setCartCount(dataArray[1].length)
      getSettingsData();
      contextValues.setUserToken(localStorage.getItem("USER_TOKEN"));

      const currentPath = location.pathname;
      const isCategoryPage = currentPath.includes("/category");
      const isProductDetailsPage = currentPath.includes("/product/"+slug);
      const isTagPage = currentPath.includes("/collection/tag/"+slug);
      if (isCategoryPage || isProductDetailsPage || isTagPage) {
        SetShowHeaderStatus(true)
      } else {
        SetShowHeaderStatus(false)
      }
    }
    didMountRef.current = false;
  }, []); 

  const getSettingsData = () => {
    ApiService.fetchData("settings").then((res) => {
      if (res.status === "success") {
        contextValues.setSettingData(res.sitesettings);
        contextValues.setSettingImageBaseUrl(res.setting_image_path);
      }
    });
  };
  const backButton = () =>{
    Navigate(-1)
}
  return (
    <>
      <header className="mheader d-flex">
        <div className="mheader-left">
          <div className="mheader-title">
            <a href={"javascript:void(0)"} onClick={(e) => backButton()}>
              <i className="ri-arrow-left-line"></i>
            </a>
            {PageName}
          </div>
        </div>
        {showHeaderStatus?
        <div className="mheader-right">
        <a className="search-link" href="/search" title="search">
          <i className="ri-search-line"></i>
        </a>
        <a
          className="cart-toggle"
          href="#"
          title="cart"
          onClick={cartModal}
        >
          <i className="ri-shopping-bag-3-line"></i>
          <span className="cart-count">{contextValues.cartCount}</span>
        </a>
      </div>:null}
        
      </header> 

      {contextValues.toggleCheckoutModal && (<CheckoutModal />)}
      {contextValues.toggleCartModal && (<CartModal />)}
      {contextValues.toggleQuickViewModal && (<QuickViewModal />)}
      {contextValues.toggleVariationModal && (<VariationModal />)}
      
    </>
  );
}
export default MobileHeader;
