import React, { useEffect, useRef, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "react-js-loader";
import DataContext from '../Elements/eventContext';
import multiCurrency from "../Elements/multi_currrency";
import { addToCart, minusToCart, removeToCart } from "../Elements/add_to_cart";
import SpinnerLoader from "../Elements/spinner_loader";
import sessionCartData from "../Elements/cart_session_data";
import constant from "../Services/constant";
import CouponModal from "./coupon_modal";
import { ApiService } from "../Services/apiservices";
import CheckoutAddressModal from "./checkout_address_modal";
import PaymentModal from "./payment_modal";
import { toast } from "react-toastify";
import { validEmail } from "../Elements/Regex";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
function CheckoutModal() {
    const Navigate = useNavigate();
    const sessionData = sessionCartData();
    const cartSessionData = sessionData[1];
    const couponSummary = sessionData[2];
    const cartSummary = sessionData[3];
    const contextValues = useContext(DataContext);
    const [defaultAddressStatus, setDefaultAddressStatus] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [hideShowPassword, setHideShowPassword] = useState(false);
    const didMountRef = useRef(true);
    const [userLoginDetails, setUserLoginDetails] = useState({
        user_email: "",
        user_password: "",
    });

    useEffect(() => {
        if (didMountRef.current) {
            contextValues.setAddressSession(sessionData[0])
            contextValues.setCartSessionData(sessionData[1])
            contextValues.setCouponSession(sessionData[2])
            contextValues.setCartSummary(sessionData[3])
            contextValues.setCartCount(sessionData[1].length)
            getSettingsData();

            if (localStorage.getItem("USER_TOKEN") != '') {
                getUserAddress()
                contextValues.setUserToken(localStorage.getItem("USER_TOKEN"));
            } else {
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            }
        }
        didMountRef.current = false;
    }, [contextValues]);

    const getSettingsData = () => {
        ApiService.fetchData("settings").then((res) => {
            if (res.status === "success") {
                contextValues.setSettingData(res.sitesettings);
                contextValues.setSettingImageBaseUrl(res.setting_image_path);
            }
        });
    };

    const deleteToCart = async (productValue) => {
        setSpinnerLoading(true)
        const updateStatus = await removeToCart(productValue, productValue.product_variation, contextValues);
        if (updateStatus) {
            setTimeout(() => {
                let cartSessionCount = localStorage.getItem("CART_SESSION");
                cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
                contextValues.setCartSessionData(cartSessionCount)
                contextValues.setCartCount(cartSessionCount.length)
                if (cartSessionCount.length == 0) {
                    window.location.reload()
                }
                setSpinnerLoading(false)
            }, 500);
        } else {
            setTimeout(() => {
                setSpinnerLoading(false)
            }, 500);
        }
    };

    const plusToCart = async (productValue, index) => {
        contextValues.setSpinnerCubLoader(index)
        const productData = {
            product_id: Number(productValue.product_id),
            product_name: productValue.product_name,
            product_slug: productValue.product_slug,
            product_image: productValue.product_image
                ? productValue.product_image
                : constant.DEFAULT_IMAGE,
            product_type: Number(productValue.product_type),
            product_price: Number(productValue.product_price),
            product_selling_price: Number(productValue.product_selling_price),
            product_discount: productValue.product_discount,
            product_variation: productValue.product_variation,
            product_category_id: productValue.product_category_id,
        };
        const updateStatus = await addToCart(productData, 1, contextValues);
        if (updateStatus) {
            contextValues.setSpinnerCubLoader(0)
        } else {
            contextValues.setSpinnerCubLoader(0)
        }
    };

    const minustocart = (productValue, index) => {
        contextValues.setSpinnerCubLoader(index)
        if (minusToCart(productValue, productValue.product_variation, contextValues)) {
            setTimeout(() => {
                contextValues.setSpinnerCubLoader(0)
            }, 500);
        } else {
            setTimeout(() => {
                contextValues.setSpinnerCubLoader(0)
            }, 500);
        }
    };

    const getUserAddress = () => {
        ApiService.fetchData("get-user-address").then((res) => {
            if (res.status === "success") {
                if (res.resAddressDefault && res.resAddressDefault.ua_id > 0) {
                    contextValues.setDefaultAddressStatus(true)
                } else {
                    contextValues.setDefaultAddressStatus(false)
                }
                setInitialLoading(false)
                if (res.resUserAddress.length > 0) {
                    contextValues.setUserAddressList(res.resUserAddress);
                } else {
                    setTimeout(() => {
                        contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
                    }, 500);
                }
            } else if (res.status === "session_out") {
                contextValues.setUserToken('')
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            } else {
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            }
        });
    };

    const checkoutModal = () => {
        contextValues.setToggleCheckoutModal(!contextValues.toggleCheckoutModal)
    }

    const couponModal = () => {
        contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
    }

    const toggleCheckoutAddressModal = () => {
        contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
    }

    const removeCoupon = () => {
        localStorage.removeItem("COUPON_SESSION");
        const couponSession = localStorage.getItem("COUPON_SESSION");
        const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
            discount_amount: 0.0,
            promo_id: 0,
            promo_code: "",
        };
        contextValues.setCouponSession(parsedCouponSession)
    }

    const togglePaymentModal = () => {
        if (!contextValues.defaultAddressStatus) {
            toast.error("Please select Address");
            return false;
        }
        contextValues.setTogglePaymentModal(!contextValues.togglePaymentModal)
    }

    const selectAddress = (addressValue) => {
        const productData = {
            ua_id: addressValue.ua_id,
        };
        ApiService.postData("chooseAddress", productData).then((res) => {
            if (res.status === "success") {
                setDefaultAddressStatus(true)
                contextValues.setUserAddressList(res.resUserAddress);
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            } else {
                setTimeout(() => {
                    setInitialLoading(false)
                }, 500);
            }
        }).catch((error) => {

        });
    }

    /* Login Section */
    const onTodoChange = (e) => {
        const { name, value } = e.target;
        setUserLoginDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const userLoginProcess = () => {
        if (userLoginDetails.user_email == '') {
            toast.error("Please enter Email Address");
            return false;
        }
        if (!validEmail.test(userLoginDetails.user_email)) {
            toast.error("Please enter valid Email Address");
            return false;
        }
        const dataString = {
            user_email: userLoginDetails.user_email,
            user_password: userLoginDetails.user_password,
            login_type_status: hideShowPassword,
        }
        setSpinnerLoading(true)
        ApiService.postData('guestCheckoutLoginProcess', dataString).then((res) => {
            if (res.status === "success") {
                localStorage.setItem("USER_TOKEN", res.user_token)
                contextValues.setUserToken(res.user_token)
                getUserAddress()
                if(couponSummary && contextValues.couponSession && contextValues.couponSession.promo_id){
                    applyCoupon()
                }
                setSpinnerLoading(false)
            } else {
                toast.error(res.message)
                setSpinnerLoading(false)
            }
        });
    };

    const openLogin = () => {
        setHideShowPassword(!hideShowPassword)
    }

    const applyCoupon = () => {
        const productData = {
          promo_code: contextValues.couponSession.promo_code,
          cart_total: cartSummary && cartSummary.sellingTotal ? cartSummary.sellingTotal : 0,
          cart_session: cartSessionData,
        };
        ApiService.postData("select-coupon", productData).then((res) => {
          if (res.data.status === 'success') {
            localStorage.removeItem("COUPON_SESSION");
            const couponSessionObj = {
              discount_amount: res.data.discount_amount,
              promo_id: res.data.promo_id,
              promo_code: res.data.promo_code,
            };
            localStorage.setItem("COUPON_SESSION", JSON.stringify(couponSessionObj));
            contextValues.setCartSummary(cartSummary[3])
            contextValues.setCouponSession(couponSessionObj)
          } else {
            localStorage.removeItem("COUPON_SESSION");
            const couponSessionObj = {
                discount_amount: 0.0,
                promo_id: 0,
                promo_code: "",
            };
            localStorage.setItem("COUPON_SESSION", JSON.stringify(couponSessionObj));
            contextValues.setCartSummary(cartSummary[3])
            contextValues.setCouponSession(couponSessionObj)
          }
        }).catch((error) => {
           
        });
      }

    return (
        <>
            <Modal show={contextValues.toggleCheckoutModal} onHide={(e) => checkoutModal()} className="checkoutModal" backdrop="static">
                {spinnerLoading && <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={50} /></div>}
                <div className="checkoutModalHeader">
                    <div className="checkoutModalHeaderTitle" onClick={(e) => checkoutModal()}>
                        <i className="d-icon-arrow-left mr-10"></i>
                        <img src="/img/logo.png" className="checkoutlogo"></img>
                    </div>
                    <img src="/img/100SECURE.png" className="wd-100"></img>
                </div>
                <div className="checkoutModalContent">
                    {initialLoading ? <div className="pageLoder"><Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={50} /></div> : <>
                        {contextValues.cartSessionData.length > 0 && (
                            <>
                                {/* Order Summary */}
                                <div className="checkoo mb-15">
                                    <div className="checkooHeader" type="button" data-bs-toggle="collapse" data-bs-target="#orderSummary" aria-expanded="false" aria-controls="orderSummary">
                                        <div className="checkooHeaderIcon">
                                            <i className="ri-shopping-bag-3-line ri-lg mr-10"></i>
                                            <span>Order Summary</span>
                                        </div>
                                        <div className="itemscount"><span className="mr-10">{contextValues.cartSessionData.length} Items</span> <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
                                    </div>
                                    <div id="orderSummary" className="collapse">
                                        {contextValues.cartSessionData.map((value, index) => {
                                            return (
                                                <div className="checkooContent" key={value.product_id}>
                                                    <div className="ccocbox">
                                                        <div className="ccocbox-product-media">
                                                            <a href={"/product/" + value.product_slug}>
                                                                <img src={value.product_image} />
                                                            </a>
                                                        </div>
                                                        <div className="ccocbox-product-details">
                                                            <div className="ccocbox-product-title">
                                                                <a href={"/product/" + value.product_slug} className="ccocbox-product-name">{value.product_name}</a>
                                                                <a href="javascript:void(0)" className="ccocbox-trash" onClick={(e) => deleteToCart(value)}><i className="ri-delete-bin-6-line"></i></a>
                                                            </div>
                                                            <div className="ccocbox-product-footer">
                                                                <div className="qtyadd">
                                                                    {contextValues.spinnerCubLoader == index + Number(1) ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : <>
                                                                        {value.quantity > 1 ? <span onClick={(e) => minustocart(value, index + Number(1))}><i className="ri-subtract-line"></i></span> : <span><i className="ri-subtract-line"></i></span>}
                                                                        <span>{value.quantity}</span>
                                                                        <span onClick={(e) => plusToCart(value, index + Number(1))}><i className="ri-add-line"></i></span>
                                                                    </>
                                                                    }
                                                                </div>
                                                                <div className="ccocbox-product-price">{multiCurrency(value.product_selling_price)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {/* Price Details */}
                                <div className="checkoo mb-15">
                                    <div className="checkooHeader" type="button" data-bs-toggle="collapse" data-bs-target="#priceDetails" aria-expanded="false" aria-controls="priceDetails">
                                        <div className="checkooHeaderIcon">
                                            <i className="ri-money-rupee-circle-line ri-lg mr-10"></i>
                                            <span>Price Details</span>
                                        </div>
                                        <div className="itemscount">
                                            <div className="itemprice mr-10">
                                                <ins className="new-price">{multiCurrency(Number(cartSummary.itemTotal) - Number(cartSummary.discount) - Number(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0))}</ins>
                                            </div>
                                            <i className="ri-arrow-down-s-line ri-lg ml-5"></i>
                                        </div>
                                    </div>
                                    <div id="priceDetails" className="collapse">
                                        <div className="checkooContent">
                                            <ul className="checkooPricelist">
                                                <li>
                                                    <span>Subtotal</span>
                                                    <span>{multiCurrency(cartSummary.itemTotal)}</span>
                                                </li>
                                                <li>
                                                    <span>Discount on MRP</span>
                                                    <span className="tx-green">-{multiCurrency(cartSummary.discount)}</span>
                                                </li>
                                                <li>
                                                    <span>Coupon Discount</span>
                                                    <span className="tx-green">-{multiCurrency(contextValues.couponSession.discount_amount)}</span>
                                                </li>
                                                <li>
                                                    <span>Shipping</span>
                                                    <span>Calculated at next step</span>
                                                </li>
                                                <li className="fw700" style={{ marginTop: "10px", fontSize: '14px' }}>
                                                    <span>Total</span>
                                                    <span>{multiCurrency(Number(cartSummary.itemTotal) - Number(cartSummary.discount) - Number(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0))}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* Coupon */}
                                {contextValues.couponSession.promo_id > 0 ?
                                    <div className="checkoo mb-15">
                                        <div className="checkooHeader">
                                            <div className="checkooHeaderIcon">
                                                <i className="ri-price-tag-3-line ri-lg mr-10"></i>
                                                <div style={{ lineHeight: '20px' }}>
                                                    <h6 className="tx-11 mb-0">{contextValues.couponSession.promo_code} applied successfully</h6>
                                                    <span className="tx-12">You save <span className="tx-green">{contextValues.couponSession.discount_amount}</span> on this order!</span>
                                                </div>

                                            </div>
                                            <div className="itemscount" style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg"></i></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="checkoo mb-15" onClick={(e) => couponModal()}>
                                        <div className="checkooHeader">
                                            <div className="checkooHeaderIcon">
                                                <i className="ri-price-tag-3-line ri-lg mr-10"></i>
                                                <span>Coupons</span>
                                            </div>
                                            <div className="itemscount"><i className="ri-arrow-right-line ri-lg"></i></div>
                                        </div>
                                    </div>
                                }
                                {/* Address & Login*/}
                                {contextValues.userToken ?
                                    <>
                                        <div className="checkoo mb-15">
                                            <div className="checkooHeader">
                                                <div className="checkooHeaderIcon" >
                                                    <i className="ri-map-pin-line ri-lg mr-10"></i>
                                                    <span>Delivery Address</span>
                                                </div>
                                            </div>
                                            <div className="addressselbox">
                                                <button type="button" className="addnewaddress" onClick={(e) => toggleCheckoutAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                                                {contextValues.userAddressList.length > 0 && contextValues.userAddressList.map((value, index) => (
                                                    <div className={`selectaddress ${value.ua_default_address == 1 ? 'active' : ''} mt-10`} key={index} onClick={(e) => selectAddress(value)}>
                                                        <div className="saddresslable">{value.ua_address_type == "Other" ? value.ua_address_type_other : value.ua_address_type}</div>
                                                        <h6 className="mb-0 tx-14">{value.ua_name}</h6>
                                                        <p className="mb-1 tx-13">{value.ua_house_no}, {value.ua_city_name}, {value.ua_state_name}, {value.ua_pincode}</p>
                                                        <p className="tx-gray mb-0 tx-12">+91 {value.ua_mobile} . {value.ua_email}</p>
                                                        <span className="selectad"></span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {contextValues.userAddressList.length > 0 ?
                                            <div className="checkoutModalFooter">
                                                <button type="button" className="btn btn-primary btn-large btn-full" onClick={(e) => togglePaymentModal()}>Proceed to pay</button>
                                            </div>
                                            :
                                            <div className="checkoutModalFooter">
                                                <button type="button" className="btn btn-primary btn-large btn-full" onClick={(e) => toggleCheckoutAddressModal()}>Add New Address</button>
                                            </div>
                                        }
                                    </> :
                                    <>
                                        <div className="formgroup mb-15">
                                            <input
                                                type="email"
                                                placeholder="Email Addresss"
                                                name="user_email"
                                                className="loginRequired"
                                                value={userLoginDetails.user_email}
                                                onChange={(e) => onTodoChange(e)}
                                            />
                                        </div>
                                        {hideShowPassword && (
                                            <div className="formgroup mb-15">
                                                <input
                                                    type="password"
                                                    name="user_password"
                                                    className="loginRequired"
                                                    placeholder="Password"
                                                    value={userLoginDetails.user_password}
                                                    onChange={(e) => onTodoChange(e)}
                                                />
                                            </div>
                                        )}

                                        <button type="button" className="btn btn-primary btn-block btn-large btn-checkoo mb-15" onClick={(e) => userLoginProcess()}>Add Address</button>
                                        <p className="text-center">Already have an account? <a href="javascript:void(0)" onClick={(e) => openLogin()}>Login</a></p>
                                        <div className="botttomcheckoo mb-30">
                                            <div className="botttomcheckooiconbox">
                                                <div className="botttomcheckooIcon"><i className="ri-emoji-sticker-line"></i></div>
                                                <p>1 Lac+ Happy Customers</p>
                                            </div>
                                            <div className="botttomcheckooiconbox">
                                                <div className="botttomcheckooIcon"><i className="ri-secure-payment-line"></i></div>
                                                <p>100% Secure Payments</p>
                                            </div>
                                            <div className="botttomcheckooiconbox">
                                                <div className="botttomcheckooIcon"><i className="ri-truck-line"></i></div>
                                                <p>5-7 Days Delivery</p>
                                            </div>
                                            <div className="botttomcheckooiconbox">
                                                <div className="botttomcheckooIcon"><i className="ri-chat-history-line"></i></div>
                                                <p>Customer Support</p>
                                            </div>
                                        </div>
                                        <p className="tx-12 tx-center tx-gray-500">By proceeding, I accept the T&C and Privacy Policy</p>
                                    </>
                                }
                            </>
                        )}
                    </>
                    }
                </div>
                {contextValues.toggleCheckoutAddressModal && (<CheckoutAddressModal />)}
                {contextValues.toggleCouponModal && (<CouponModal />)}
                {contextValues.togglePaymentModal && (<PaymentModal />)}
            </Modal>
            {/* </div> */}
        </>
    )
}

export default CheckoutModal
