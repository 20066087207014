import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ApiService } from '../Services/apiservices';
import { BrowserView, MobileView } from "react-device-detect";
let apiServices = new ApiService();
function Offers({sliderData,sliderImg,handleClose,handleShow,show}) {
    const didMountRef = useRef(true);
    useEffect(() => {
            const visitedBefore = localStorage.getItem('visitedNewsletter');
            if (!visitedBefore) {
                setTimeout(function(){
                    handleShow();
                    localStorage.setItem('visitedNewsletter', 'true');
                }, 10)
            }
    }, [sliderData, sliderImg])
    return (
        <>
        <BrowserView>
        <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className='modal-body-with-background'
                    style={{
                        backgroundImage: `url(${sliderImg + sliderData.slider_image})`,
                        height: '496px'
                    }}>
                    <span><button type="button" className="btn-close" onClick={handleClose}></button></span>
                </Modal.Body>
            </Modal>
        </BrowserView>
        <MobileView>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className='modal-body' style={{
                        padding: `5px`
                    }}>
                    <span><button type="button" className="btn-close" onClick={handleClose}></button></span>
                    {sliderImg + sliderData.slider_image != "" ?
                    <img src={sliderImg + sliderData.slider_image} style={{
                        width: `100%`
                    }} /> : ""
                    }
                </Modal.Body>
            </Modal>
        </MobileView>
        </>
    );
}
export default Offers;