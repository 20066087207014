import React, { useEffect, useState, useRef,useContext } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Productlistsidebar from "../../Components/Elements/product_list_sidebar";
import MobileHeader from "../../Components/Elements/mobile_header";
import sessionCartData from "../../Components/Elements/cart_session_data";
import Modal from "react-bootstrap/Modal";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { Helmet } from "react-helmet";
import DataContext from "../../Components/Elements/eventContext";
import ProductBox from "../../Components/Elements/product_box";

function Collection({onParentData}) {
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [resProductsData, setProductsData] = useState([]);
  const [cartCount, setCartCount] = useState(parsedCartSession.length);
  const [AttributeData, setAttributeData] = useState([]);
  const [categoriesData, setCategoriesData] = useState({});
  const [categoriesImageUrl, setCategoriesImageUrl] = useState("");
  const [loading, setLoading] = useState();
  const [sorting, setSorting] = useState("");
  const [CatName, setCatName] = useState("");
  const [catDesc, setCatDesc] = useState("");
  const [catImage, setCatImage] = useState("");
  const { type, slug } = useParams();
  const [productData, setProductData] = useState(null);
  const [scroll, setScroll] = useState();
  const [helmetData , setHelmetData] = useState("")
  const [showSort, setShowSort] = useState(false);
  const handleShowSortModal = () => {
    setShowSort(true);
  };
  const handleChildSortModalData = (status) => {
    setShowSort(status);
  };

  const [showFilter, setShowFilter] = useState(false);
  const handleShowFilterModal = () => {
    setShowFilter(true);
  };
  const handleChildFilterModalData = (status) => {
    setShowFilter(status);
  };
  const [showVariation, setShowVariation] = useState(false);
  const handleShowVariation = (data) => {
    setProductData(data);
    setShowVariation(true);
  };
  const handleChildVariationModalData = (status) => {
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    setCartCount(parsedCartSession.length)
    setShowVariation(status);
    // onParentData(status)
  };
  useEffect(() => {
    setLoading(true);
    if (didMountRef.current) {
      getProductList();
        const dataString = {
             type: type,
            cat_slug: slug,
        };
        ApiService.postData("category-detail", dataString).then((res) => {
          if (res.status === "success") {
            setHelmetData(res.hemlet);
          }
      });
    }
    didMountRef.current = false;
  }, []);
  
  const getProductList = () => {
    let pageNumber = Math.ceil(resProductsData.length / 12) + 1;
    const dataString = {
      type: type,
      slug: slug,
    };
    ApiService.fetchData(
      "product-list" +
        "?page=" +
        pageNumber +
        "&_limit=12&slug=" +
        slug +
        "&type="+type
    ).then((res) => {
      if (res.status === "success") {
        setCategoriesData(res.categoriesData);
        setCatImage(res.categoriesData.cat_banner_image);
        setCategoriesImageUrl(res.category_img_url);
        setCatName(res.tagsData.tag_name);
        setCatDesc(res.categoriesData.cat_desc)
        if (res.resProductsData.data.length > 0) {
          const apires = res.resProductsData.data;
          const mergeData = [...resProductsData, ...apires];
          setProductsData(mergeData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const handleFilteredData = (data) => {
    setProductsData(data);
  };

  const handleSortingClick = (sortingValue) => {
    setSorting(sortingValue);
  };

  const handlecatName = (data) => {
    setCatName(data);
  };
  const handlecatImage = (categoryData) => {
    setCatImage(categoryData);
  };
  const setscrolldata = (scroll) => {
    setScroll(scroll);
  };

  const handleClosesort = () => {
    setShowSort(false)
  };

  const contextValues = useContext(DataContext);


  return (
    <>
    <Helmet>
        <title>{helmetData.title}</title>
        <meta name="description" itemprop="description" content={helmetData.description != null ? helmetData.description :"Chokhi Dhani foods"} />
        <meta name="keywords" content={helmetData.keywords ? helmetData.keywords:"Chokhi Dhani foods"} /> 
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={helmetData.title ? helmetData.title:"Chokhi Dhani foods"} />
        <meta property="og:image" content= {helmetData.image}/>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content= {helmetData.description != null ?helmetData.description:"Chokhi Dhani foods"} />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={helmetData.title? helmetData.title: "Chokhi Dhani foods"} />
        <meta name="twitter:description" content={helmetData.description != null ?helmetData.description:"Chokhi Dhani foods"} />
        <meta property="twitter:image" content= {helmetData.image}/>
    </Helmet>
      <BrowserView>
      <Header state="inner-header" cartCount={cartCount} />
        <main>
          <div className="page-content mt-5 mb-5">
            <Container>
              <Row>
                <Productlistsidebar
                  sorting={sorting}
                  slug={slug}
                  type={type}
                  onFilteredData={handleFilteredData}
                  categoryName={handlecatName}
                  categoryImage={handlecatImage}
                  setscroll={setscrolldata}
                  showmodal={showFilter}
                />
                <Col lg={9}>
                {categoriesData && (
                  <>
                    {loading === true ? (
                      <div className="cat-add-banner mb-3">
                        <Skeleton width="100%" height={200} />
                      </div>
                    ) : (
                      catImage && (
                        <div className="cat-add-banner mb-3">
                          <img
                            src={categoriesImageUrl + catImage}
                            alt={categoriesData.cat_name}
                            style={{ width: "100%" }}
                          />
                        </div>
                      )
                    )}
                  </>
                )}
                     <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {/* {CatName ? CatName : <Skeleton width={100} height={10} />} */}
                      {CatName ? CatName : "All Product" }

                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <div className="page-title mb-2">
                    <h2 className="mb-0">
                      {/* {CatName ? CatName : <Skeleton width={200} height={30} />} */}
                      {CatName ? CatName : "All Product"}
                    </h2>
                  </div>
                  <div className="shortby">
                    <div className="productcount">
                      Showing results of {resProductsData.length} products.
                    </div>
                    <div className="shortbydrop">
                      <div className="shortbydrop-inner select">
                        <label>Sort by</label>
                        <select
                          onChange={(e) => handleSortingClick(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="featured">Featured</option>
                          <option value="bestselling">Best selling</option>
                          <option value="alphaasc">Alphabetically, A-Z</option>
                          <option value="alphadesc">Alphabetically, Z-A</option>
                          <option
                            value="asc"
                            selected={sorting === "asc" ? true : false}
                          >
                            Price, low to high
                          </option>
                          <option
                            value="desc"
                            selected={sorting === "desc" ? true : false}
                          >
                            Price, high to low
                          </option>
                          <option value="dateasc">Date, old to new</option>
                          <option value="datedesc">Date, new to old</option>
                        </select>
                      </div>
                    </div> 
                  </div>
                  <InfiniteScroll
                    dataLength={resProductsData.length}
                    next={scroll == true ? getProductList : ""}
                    hasMore={true}
                    endMessage={<p>No more data to load.</p>}
                    style={{ overflow: "hidden !important" }}
                  >
                    {loading === true ? (
                      <Row>
                        {[...Array(9)].map((_, index) => (
                          <Col lg={4}>
                            <div className="product" key={index}>
                              <figure className="product-media">
                                <Skeleton
                                  variant="text"
                                  width={280}
                                  height={315}
                                />
                              </figure>
                              <div className="product-details">
                                <h3 className="product-name">
                                  <Skeleton variant="text" width={150} />
                                </h3>
                                <div className="product-price">
                                  <Skeleton variant="text" width={150} />
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <>
                        {resProductsData.length > 0 ? (
                          <Row>
                            {resProductsData.map((value, indexProduct) => {
                              const productPrice = value.product_price;
                              const productSellingPrice =
                                value.product_selling_price;
                              const discount = (
                                ((productPrice - productSellingPrice) /
                                  productPrice) *
                                100
                              ).toFixed(0);
                              return (
                                <Col lg={4} key={indexProduct}>
                                <ProductBox productValue={value} indexProduct={indexProduct} classType="product" key={indexProduct} />

                                </Col>
                              );
                            })}
                          </Row>
                        ) : (
                          <h2>No More Products found.</h2>
                        )}
                      </>
                    )}
                  </InfiniteScroll>
                  <section>
                  <div dangerouslySetInnerHTML={{ __html: catDesc }}></div>
                

                  </section>
                </Col>
              </Row>
            </Container>
          </div>
        </main>
        <Footer />
      </BrowserView>

      <MobileView>
        <MobileHeader
          PageName={CatName ? CatName : "All Product"}
          Route=""
          cartCount={cartCount}
        />
        <main className="main">
          <div className="page-content mt-3 mb-5">
            <Container>
             
              <InfiniteScroll
                dataLength={resProductsData.length}
                next={scroll == true ? getProductList : ""}
                hasMore={true}
                endMessage={<p>No more data to load.</p>}
                style={{ overflow: "hidden !important" }}
              >
                {loading === true ? (
                  <div className="row g-2">
                    {[...Array(9)].map((_, index) => (
                      <div className="col-6" key={index}>
                        <div className="product" key={index}>
                          <figure className="product-media">
                            <Skeleton
                              variant="text"
                              width={"100%"}
                              height={170}
                            />
                          </figure>
                          <div className="product-details">
                          <div className="product-details-inner">
                            <h3 className="product-name">
                              <Skeleton
                                variant="text"
                                width={"100%"}
                                height={10}
                              />
                            </h3>
                            <div className="product-price">
                              <Skeleton variant="text" width={100} height={5} />
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {resProductsData.length > 0 ? (
                      <div className="row g-2">
                        {resProductsData.map((value, indexProduct) => {
                          const productPrice = value.product_price;
                          const productSellingPrice =
                            value.product_selling_price;
                          const discount = (
                            ((productPrice - productSellingPrice) /
                              productPrice) *
                            100
                          ).toFixed(0);
                          return (
                            <div className="col-6" key={indexProduct}>
                            <ProductBox productValue={value} indexProduct={indexProduct} classType="product" />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <h2>No More Products found.</h2>
                    )}
                  </>
                )}
              </InfiniteScroll>
            </Container>
            <div className="mfb-section">
              <div className="mfb-section-inner">
                <div
                  className="mfb-box border-right"
                  onClick={handleShowSortModal}
                >
                  <i className="d-icon-arrow-up"></i>
                  <i className="d-icon-arrow-down me-2"></i>Sort By
                </div>
                <div className="mfb-box" onClick={handleShowFilterModal}>
                  <i className="d-icon-filter-2 me-2"></i>Filter
                </div>
              </div>
            </div>
          </div>
        </main>
        <Productlistsidebar
          sorting={sorting}
          slug={slug}
          type={type}
          onFilteredData={handleFilteredData}
          categoryName={handlecatName}
          categoryImage={handlecatImage}
          setscroll={setscrolldata}
          showmodal={showFilter} 
          HandleCloseModal={handleChildFilterModalData}
        />
      </MobileView>
      {showSort && (
        <Modal show={showSort} onHide={handleClosesort} className="sortModal bottom">
        <div className="sortModalbox">
        <button onClick={handleClosesort} className="pop-close"><i className="d-icon-times"></i></button>
          <ul>
                <li onClick={(e) => { handleSortingClick('featured'); setShowSort(false); }}>
              <span>Featured</span>
              <input type="checkbox" value="featured" checked={sorting=='featured'?true:false}/>
            </li>
            <li onClick={(e) => { handleSortingClick('bestselling'); setShowSort(false); }}>
              <span>Best selling</span>
              <input type="checkbox" value="bestselling" checked={sorting=='bestselling'?true:false}/>
            </li>
            <li onClick={(e) => { handleSortingClick('alphaasc'); setShowSort(false); }}>
              <span>Alphabetically, A-Z</span>
              <input type="checkbox" value="alphaasc" checked={sorting=='alphaasc'?true:false}/>
            </li>
            <li onClick={(e) => { handleSortingClick('alphadesc'); setShowSort(false); }}>
              <span>Alphabetically, Z-A</span>
              <input type="checkbox" value="alphadesc" checked={sorting=='alphadesc'?true:false}/>
            </li>
            <li onClick={(e) => { handleSortingClick('asc'); setShowSort(false);}}>
              <span>Price, low to high</span>
              <input type="checkbox" value="asc" checked={sorting=='asc'?true:false}/>
            </li>
            <li onClick={(e) => { handleSortingClick('desc'); setShowSort(false); }}>
              <span>Price, high to low</span>
              <input type="checkbox" value="desc" checked={sorting=='desc'?true:false}/>
            </li>
            <li onClick={(e) => { handleSortingClick('dateasc'); setShowSort(false); }}>
              <span>Date, old to new</span>
              <input type="checkbox" value="dateasc" checked={sorting=='dateasc'?true:false}/>
            </li>
            <li onClick={(e) => { handleSortingClick('datedesc'); setShowSort(false); }}>
              <span>Date, new to old</span>
              <input type="checkbox" value="datedesc" checked={sorting=='datedesc'?true:false}/>
            </li>
            </ul>
        </div>
      </Modal>

      )}
      
    </>
  );
}
export default Collection;
